<template>
  <div>
    <div class="navigation_results">
      <div class="container-fluid">
        <div class="wrapper">
          <div class="row justify-content-center">
            <div
              class="
                col-sm-3
                p-1
                d-flex
                justify-content-center
                align-items-center
                my-1
              "
            >
              <div class="result_blog">
                <span class="true">{{ display.array_index }}</span>
                <span>/</span>
                <span class="wrong">{{ number_count }}</span>
              </div>
            </div>
            <div
              class="
                col-sm-3
                p-1
                d-flex
                justify-content-center
                align-items-center
                my-1
              "
            >
              <div class="nav">
                <ul class="main_menu">
                  <li class="main_menu_li">
                    <router-link :to="{ name: 'home' }">
                      <i class="fas fa-home"></i>
                    </router-link>
                  </li>
                  <li class="main_menu_li">
                    <router-link :to="{ name: 'display_pro_settings' }">
                      <i class="fas fa-cogs"></i>
                    </router-link>
                  </li>
                  <li v-if="!vars.finished" class="main_menu_li">
                    <a href="#" @click.prevent="replay">
                      <i class="fas fa-sync-alt"></i>
                    </a>
                  </li>
                  <li class="main_menu_li">
                    <a href="#" @click.prevent="newPlay">
                      <i class="fas fa-play"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div v-if="game.isPlaying && !game.isChecked" class="container-fluid">
        <transition mode="out-in" name="fade">
          <span :key="display.array_index" :class="classObject" class="number">
            {{ display.current | son }}
          </span>
        </transition>
      </div>
      <div v-if="!game.isPlaying && !game.isChecked" class="container-fluid">
        <div class="wrapper">
          <input
            id="answer"
            ref="answer"
            v-model="game.user_answer"
            class="answer_input"
            type="number"
            @keyup.enter="check"
          />
        </div>
      </div>
      <div v-if="game.isChecked" class="container-fluid">
        <div class="wrapper">
          <div class="multiply_numbers">
            <span
              :class="
                vars.isRightAnswer ? vars.classes.success : vars.classes.error
              "
              class="multiply_number"
            >
              {{ display.summary_text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "start",
  data: function () {
    return {
      game: {
        isIntro: true,
        isPlaying: false,
        isChecked: false,
        user_answer: "",
      },
      vars: {
        isRightAnswer: "",
        classes: {
          success: "text-success",
          error: "text-danger",
        },
        audio: {
          success: "",
          fail: "",
        },
        finished: false,
        music: "",
        interval: "",
      },
      display: {
        current: "",
        start_index: 0,
        array_index: "-",
        summary_text: "",
      },
    };
  },
  computed: {
    classObject: function () {
      if (this.sonlen === 1) {
        return { number_1: true, "text-success": this.game.isIntro };
      }
      if (this.sonlen === 2) {
        return { number_2: true, "text-success": this.game.isIntro };
      }
      if (this.sonlen === 3) {
        return { number_3: true, "text-success": this.game.isIntro };
      }
      if (this.sonlen === 4) {
        return { number_4: true, "text-success": this.game.isIntro };
      }
      if (this.sonlen === 5) {
        return { number_5: true, "text-success": this.game.isIntro };
      }
      if (this.sonlen === 6) {
        return { number_6: true, "text-success": this.game.isIntro };
      }
      if (this.sonlen === 7) {
        return { number_7: true, "text-success": this.game.isIntro };
      }
      return { number: true, "text-success": this.game.isIntro };
    },
    sonlen() {
      if (typeof this.display.current === "number") {
        if (parseInt(this.display.current) < 0) {
          return (this.display.current + "").length - 1;
        }
      }
      return (this.display.current + "").length;
    },
    pro() {
      return this.$store.getters["pro/pro"];
    },
    isReady() {
      return this.pro.isReady;
    },
    games() {
      return this.$store.getters["game/game"];
    },
    numbers() {
      return this.pro.numbers;
    },
    answer() {
      return this.numbers.reduce((a, b) => a + b);
    },
    settings() {
      return this.pro.settings;
    },
    number_count() {
      return this.numbers.length;
    },
  },
  methods: {
    startBeginning() {
      this.game.isIntro = true;
      this.game.isPlaying = true;
      this.vars.interval = setInterval(
        this.doBeginning,
        this.games.start.time * 1000
      );
    },
    doBeginning() {
      if (
        typeof this.games.start.texts[this.display.start_index] === "undefined"
      ) {
        clearInterval(this.vars.interval);
        this.display.start_index = 0;
        this.startGaming();
      } else {
        this.display.current = this.games.start.texts[this.display.start_index];
        this.display.start_index++;
      }
    },
    startGaming() {
      this.game.isIntro = false;
      this.display.array_index = 0;
      this.doGaming();
      this.vars.interval = setInterval(
        this.doGaming,
        this.settings.number_speed * 1000
      );
    },
    async doGaming() {
      if (typeof this.numbers[this.display.array_index] === "undefined") {
        this.game.isPlaying = false;
        await clearInterval(this.vars.interval);
        await this.focus();
      } else {
        this.playAudio(this.games.audios.alert);
        this.display.current = this.numbers[this.display.array_index];
        this.display.array_index++;
      }
    },
    replay() {
      clearInterval(this.vars.interval);
      this.display.current = "";
      this.game.isPlaying = true;
      this.game.isChecked = false;
      this.$store.commit("pro/INC_ATTEMPTS");
      this.startBeginning();
    },
    async newPlay() {
      this.vars.finished = false;
      clearInterval(this.vars.interval);
      this.display.current = "";
      this.game.isPlaying = true;
      this.game.isChecked = false;
      if (await this.$store.dispatch("pro/prepare", this.settings)) {
        this.startBeginning();
      }
    },
    focus() {
      this.$refs.answer.focus();
    },
    check() {
      if (parseInt(this.game.user_answer) === this.answer) {
        this.vars.audio.success.play();
        this.vars.isRightAnswer = true;
        this.$store.dispatch("pro/complete", true);
        this.display.summary_text = this.game.user_answer + " = " + this.answer;
        this.vars.finished = true;
      } else {
        this.vars.audio.fail.play();
        this.vars.isRightAnswer = false;
        this.$store.dispatch("pro/complete", false);
        this.display.summary_text = this.game.user_answer + " ≠ " + this.answer;
      }
      this.game.user_answer = "";
      this.game.isChecked = true;
    },
    playAudio(src) {
      this.vars.music = new Audio(src);
      this.vars.music.play();
    },
    async goNextNumber() {
      if (!this.isIntro) {
        if (this.numbers.length - this.display.array_index > 0) {
          clearInterval(this.vars.interval);
          await this.doGaming();
          this.vars.interval = setInterval(
            this.doGaming,
            this.settings.number_speed * 1000
          );
        }
      }
    },
    setKeyEvents() {
      window.addEventListener("keydown", (e) => {
        if (
          (!this.game.isIntro && this.game.isPlaying && e.key === "Enter") ||
          (this.game.isChecked && e.key === "Enter")
        ) {
          // this.goNextNumber();
          this.newPlay();
        }
      });
    },
    removeKeyEvents() {
      window.removeEventListener("keydown", (e) => {
        if (
          (!this.game.isIntro && this.game.isPlaying && e.key === "Enter") ||
          (this.game.isChecked && e.key === "Enter")
        ) {
          // this.goNextNumber();
          this.newPlay();
        }
      });
    },
  },
  mounted() {
    if (this.isReady) {
      this.vars.music = new Audio(this.games.audios.alert);
      this.vars.audio.success = new Audio(this.games.audios.success);
      this.vars.audio.fail = new Audio(this.games.audios.fail);
      this.startBeginning();
      this.setKeyEvents();
    } else {
      this.$router.push({ name: "display_pro_settings" });
    }
  },
  beforeDestroy() {
    clearInterval(this.vars.interval);
    this.$store.commit("pro/CLEAR_DATA");
    this.removeKeyEvents();
  },
};
</script>
